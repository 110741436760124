@import url(../iconfont/css/iconfont.css);
@import url(../iconfont/css/animation.css);
/*@import url(http://fonts.useso.com/css?family=Open+Sans:300,300italic,400,400italic,600,600italic|Anonymous+Pro:400,400italic,700,700italic);*/
@import url(../fonts/fonts.css);

.mask {
    position: fixed;
    z-index: 100;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #000;
    opacity: 0;
    transition: opacity .3s;
}

.msgbox {
    position: fixed;
    z-index: 101;
    left: 0;
    top: 150px;
    background: #fff;
    overflow-y: auto;
    overflow-x: hidden;
    opacity: 0;
    transition: top .3s, opacity .3s;
    border-radius: 3px;

    br: 3px;

    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.26);

    .icon-close {
        position: absolute;
        top: 0;
        right: 0;
        width: 44px;
        height: 50px;
        font-size: 14px;
        cursor: pointer;
        text-align: center;
        line-height: 50px;
        border-radius: 0 3px 0 0;

        &:before {
            margin: 0;
        }

        &:hover {
            background: #f8f8f8;
        }
    }

    h3 {
        height: 51px;
        padding: 0 20px;

        font-size: 18px;
        line-height: 50px;
        border-bottom: 1px solid #efefef;

        .counter {
            margin-left: 10px;
            color: red;

            &:before {
                color: grey;
                content: '(';
            }

            &:after {
                color: grey;
                content: ')';
            }
        }

        .close-counter {
            margin-left: 10px;
            color: red;

            &:before {
                content: '';
            }

            &:after {
                content: ' 秒后关闭本窗口';
            }
        }
    }

    .msg-wrap {
        min-width: 530px;
        padding: 20px 20px;
        font-size: 18px;
        text-align: center;

        .pop-text {
            color: #333333;
            font-size: 16px;
            text-align: left;
            max-width: 540px;
        }

        .warning-msg {
            position: relative;
            display: block;
            margin: 0 50px;

            .icon-warning {
                position: absolute;
                top: -13px;
                left: -50px;
                font-size: 32px;
                color: #F0D967;
                vertical-align: top;
            }
        }

        .warning-btns {
            margin-top: 40px;

            .btn+.btn,
            button+.btn {
                margin-left: 80px;
            }
        }

        .character-box{
            width:450px;
            height:70px;
            background:#F7F7F7;
            padding:10px 20px;
            .mb10;
            .name-line{
                font-size:18px;
                .character{
                    color:#4a4a4a;
                    .fl;
                    .inline-block;
                }
                .target-login{color:#4A90E2;.inline-block;.fr;}
            }
            .school-line{
                font-size:14px;
                color:#757575;
            }
        }
    }
}

.w1000 {
    width: 1000px;
    margin-left: auto;
    margin-right: auto;
}
.white-box{
    background:#ffffff;
}
.grey-box{
    background:#f9f9f9;
}
.dark-grey-box{background:#f8f8f8;}
#container {
    background-color: #F1F1F1;

    .login-block{
        &.xishan-block{
            background: url("/static/images/wuxi/xishan_banner.png") no-repeat;
            background-size: 100% 100%;
        }
        &.login-bg{
            background: url("/static/images/wuxi_bg.png") no-repeat;
            background-size: 100% 100%;
        }

        &.extra-bg{
            background: url("/static/images/default-login-bg.png") no-repeat;
            background-size: 100% 100%;
        }

        .plat-info{
            width:400px;
            height:350px;
            padding-top:100px;
            .info-box{
                height:100px;
                .plat{
                    font-size:40px;
                    font-weight:bold;
                    border-bottom:1px solid black;
                }
                .info{
                    font-size:26px;
                }
                .download-btn{
                    width:140px;
                    height:50px;
                    line-height:50px;
                    background:#ffffff;
                    .app-download-new{
                        font-size:16px;
                        color:#D63E3E;
                    }
                }
            }
        }

        .login-box{
            width: 280px;
            height:290px;

            background:#ffffff;
            padding:10px 20px 10px 20px;
            .login-type{
                .login-span{
                    .type{
                        .tac;
                        .inline-block;
                        width:49%;
                        font-size: 16px;
                        &.active{
                            .type-text{
                                color:#D63E3E;
                            }
                            .b-line{
                                background: #D63E3E;
                                border-radius: 3px;
                                height: 3px;
                                width: 20%;
                                margin-left: 40%;
                            }
                        }
                    }
                }
            }
        }

        .head-block{
            .avatar-block{
                .inline-block;
                border-radius: 50%;
                width:36px;
                height:36px;
                img{
                    border-radius: 50%;
                    width:100%;
                    height:100%;
                }
            }
            .character-block{
                .inline-block;
                font-size:25px;
            }
            .target-btn{
                .inline-block;
                .tac;
                padding:5px 10px;
                width:120px;
                border-radius:5px;
                font-size:16px;
            }
            .target-btn-change{
                .target-btn;
                color:#D63E3E;
                background-color:#ffffff;
            }
            .target-btn-login{
                .target-btn;
                color:#ffffff;
                background-color:#D63E3E;
            }
            .toa-btn-login{
                .target-btn;
                background-color:#ffffff;
                color:#D63E3E;
            }
        }

        .auth-block{
            color:#666666;
            font-size:14px;
            margin-left: 46px;
            width:70%;
            .auth-name{
                .inline-block;
                .fl;
                .tal;
            }
            .auth-box{
                .inline-block;
                max-width:80%;
            }
        }
    }
}

.wuxi-select{
    &.sm{width:90px;}
    &.mid{width:160px;}
    &.lg{width:180px;}
    /* --ie清除--*/
    &::-ms-expand{ display: none; }
    /* --火狐、谷歌清除--*/
    appearance:none;
    -moz-appearance:none;
    -webkit-appearance:none;
    background: url("/static/images/select-arrow.png") no-repeat scroll right center transparent;
    padding-right: 14px;
    background-color:#ffffff;

    &:hover{
        cursor:pointer;
    }

    option::-ms-expand{ display: none; }

    option{
        -moz-appearance:none;
        -webkit-appearance:none;
        background-color:#ffffff;
    }
    /* --背景色字体颜色--*/
    option:hover{
        color:#ffffff;
        background-color:#1E90FF;
    }
}

.login-form{
    .box-title{
        height:18px;
        line-height:18px;
        .login-title{
            font-size:16px;
            color:#D63E3E;
        }
        .app-download-new{
            font-size:16px;
        }
    }
    button{
        width: 100%;
        height: 36px;
        font-size: 14px;
        color: #fff;
        background: #D63E3E;
        &:hover{
            background: #FF4A4A;
        }
    }

    .input {
        position: relative;
        input{
            width: 100%;
            height: 36px;
            padding: 0 5px 0 63px;
            border: 1px solid #E0E0E0;
        }
        &.error{
            border-color: #D63E3E;
            transition-duration: .2s;
        }
        & > i {
            position: absolute;
            left: 20px;
            top: 5px;
            width: 24px;
            height: 24px;
            font-size: 24px;
            color: #cacaca;
            line-height: 1;
        }
        & > i:before {
            margin: 0;
        }
        img{
            position: absolute;
            right: 0;
            top: 0;
            max-width: 120px;
            max-height: 36px;
            cursor: pointer;
        }
    }
}

.platforms{
    .g-bg {
        &.hidden { display: none; }
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, .3);
        z-index: 2;
    }

    min-height:200px;
    .plat-block{
        // box-shadow:0px 1px 4px rgba(0, 0, 0, 0.1);
        // border-radius:5px;
        min-height:150px;
        overflow: hidden;
        position:relative;
        &:not(:last-child){
            margin-bottom:20px;
        }
        .plat-box{
            //.inline-block;
            // width:4%;
            // overflow: hidden;
            // padding-bottom:10000px;
            // margin-bottom: -10000px;
            // .fl;
            .plat-title{
                //border-radius:5px 0px 0px 5px;
                //min-height:150px;
                width:100%;
                height:4px;
                border-radius:2px;
            }
            &.plat-title0{
                .plat-title;
                background:#D63E3E;
            }
            &.plat-title1{
                .plat-title;
                color:#F8AC57;
                background:#F8AC57;
            }
            &.plat-title2{
                .plat-title;
                color:#5BC0EB;
                background:#5BC0EB;
            }
            &.plat-title3{
                .plat-title;
                color:#9BC53D;
                background:#9BC53D;
            }
            &.plat-title4{
                .plat-title;
                color:#EDA931;
                background:#EDA931;
            }
            &.plat-title5{
                .plat-title;
                color:#FA7921;
                background:#FA7921;
            }
            &.plat-title6{
                .plat-title;
                color:#D7B292;
                background:#D7B292;
            }
            &.plat-title7{
                .plat-title;
                color:#8A8CFB;
                background:#8A8CFB;
            }
            &.plat-title8{
                .plat-title;
                color:#4A90E2;
                background:#4A90E2;
            }
            .plat-info{
                font-size:18px;
            }
        }

        .plat-text{
            position:absolute;
            left:-6px;
            z-index:10;
            height:100%;
            width:4%;
            color:#ffffff;
            font-size:18px;
            writing-mode:tb;
            letter-spacing: 10px;
        }
        .plat-info-box{
            //color:#D63E3E;
            font-size:24px;
            .fw500;
        }
        .plat-items{
            width:100%;
            // overflow: hidden;
            // padding-bottom:10000px;
            // margin-bottom: -10000px;
            .statistic-item{
                display:inline-block;
                width:130px;
                .fl;
                &:not(:last-child){
                    margin-right:40px;
                }
                .statistic-icon{
                    width:80px;
                    height:70px;
                    line-height:70px;
                    display:inline-block;
                }
                .statistic-name{
                    .tac;
                    p{
                        margin-bottom:5px;
                    }
                    .name{
                       color:#757575;
                       font-size:14px;
                    }
                    .count{
                        font-size:32px;
                    }
                 }
            }
            .plat{
                // &:not(:nth-of-type(7)){
                //     margin-right:35px;
                // }
                .plat-item{
                    position:relative;
                    &:hover{
                        cursor:pointer;
                    }
                    display:inline-block;
                    width: 140px;
                    height: 150px;

                    .region-type {
                        position:absolute;
                        width:22px;
                        height:22px;
                        border-radius:8px 0 8px 0;
                        z-index:5;
                        left: 87px;
                        top: -10px;
                        color:#ffffff;
                        &.type1{
                            background:#00AC4D;
                            border:1px solid #ffffff;
                        }
                        &.type0{
                            background:#F5B368;
                            border:1px solid #ffffff
                        }
                    }

                    .plat-icon{
                        width:60px;
                        height:60px;
                        //line-height:80px;
                        border-radius: 6px;
                        font-size: 36px;
                        color: #ffffff;
                        display:inline-block;
                        img{
                            width:100%;
                            height:100%;
                            border-radius: 6px;
                        }
                    }
                    .plat-name{
                        //white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        font-size:16px;
                        padding:0 20px;
                        margin-top:5px;
                    }
                    .plat-icon-1{
                        .plat-icon;
                    }
                    .plat-icon-2{
                        .plat-icon;
                    }
                    .plat-icon-3{
                        .plat-icon;
                    }
                    .plat-icon-4{
                        .plat-icon;
                    }
                    .plat-icon-5{
                        .plat-icon;
                    }
                    .plat-icon-6{
                        .plat-icon;
                    }
                    .plat-icon-7{
                        .plat-icon;
                    }
                    .plat-icon-8{
                        .plat-icon;
                    }

                }

                .code-img {
                    position: absolute;
                    z-index: 10;
                    width:70px;
                    height:70px;
                    left:35px;
                }

                .wuxi-qrcode {
                    width:70px;
                    height:70px;
                    text-align:center;
                    vertical-align:middle;
                    border-radius: 4px;
                    background-color: rgba(255, 255, 255, 1);
                }
            }
        }
        &.local {
            overflow: visible;
            .plat-items {
                position: relative;
                img.tip {
                    &.hidden {
                        display: none;
                    }
                    position: absolute;
                    z-index: 10;
                    cursor: pointer;
                    top: 20px;
                    left: -169px;
                }
            }
        }
    }
}

.reset-password-block{
    padding:50px 0px 50px 0px;
    .pw-tip {
        color: #ff3b30;
        font-size: 16px;
        padding-left: 180px;
    }
    .input{
        position: relative;
        label{
            display: inline-block;
            text-align: left;
            min-width: 65px;
            margin-right: 50px;
            font-size: 16px;
        }
        input{
            width: 300px;
            height: 42px;
            padding: 10px 5px 10px 15px;
            font-size: 16px;
            border: 1px solid #E0E0E0;
        }
    }
    .error-msg-wrap{
        position: relative;
        margin-bottom: 5px;
        height: 21px;
        .error-msg{
            position: absolute;
            margin-left: 406px;
            font-size: 14px;
            color: #FF3B30;
            white-space: nowrap;
        }
    }
    button{
        width: 300px;
        height: 50px;
        font-size: 16px;
        margin-left: 115px;
        color: #fff;
        background: #D63E3E;
    }
}

.fix-link-block{
    .fix-link{
        .inline-block;
        .fl;
        .hover-pointer;
        height:70px;
        border:1px solid #e5e5e5;
    }
    .fix-link1{
        .fix-link;
        width:240px;
        margin-right: 13px;
        background:url("/static/images/wuxi/1.png") no-repeat;
    }
    .fix-link2{
        .fix-link;
        width:240px;
        margin-right: 13px;
        background:url("/static/images/wuxi/2.png") no-repeat;
    }
    .fix-link3{
        .fix-link;
        width:240px;
        margin-right: 13px;
        background:url("/static/images/wuxi/3.png") no-repeat;
    }
    .fix-link4{
        .fix-link;
        width:240px;
        background:url("/static/images/wuxi/4.png") no-repeat;
    }
    .fix-link5{
        .fix-link;
        width:325px;
        margin-right:12px;
        background:url("/static/images/wuxi/5.png") no-repeat;
    }
    .fix-link6{
        .fix-link;
        width:325px;
        margin-right:12px;
        background:url("/static/images/wuxi/6.png") no-repeat;
    }
    .fix-link7{
        .fix-link;
        width:325px;
        background:url("/static/images/wuxi/7.png") no-repeat;
    }
}

.blue{
    color:#4A90E2;
}

.red{
    color:#D63E3E;
}

.hover-pointer:hover{cursor:pointer;}
.select-box {
    height:60px;
    line-height:60px;
    color: #fff;

    .area{
        font-size:16px;
        color:#ffffff;
        min-width:120px;
    }
    .area_list{
        border-radius: 5px;
        z-index:100;
        position:relative;
        .district{
            .tac;
            color:#ffffff;
            background-color:#4A4A4A;
            width:120px;
            height:40px;
            line-height:40px;
            &:not(:last-child){
                border-bottom: 1px solid #979797;
            }
            &:hover,&.active{
                background-color:#979797;
            }
        }
    }
    .logout{
        font-size:16px;
        color:#ffffff;
        min-width:120px;
        a{
            color:#ffffff;
        }
    }
}
.line-txt {
    position: relative;
    text-align: center;
}

.line-txt:before {
    position: absolute;
    z-index: 1;
    top: 10px;
    left: 0;
    width: 100%;
    border-top: 1px solid #E0E0E0;
    content: '';
}

.line-txt span {
    position: relative;
    z-index: 2;
    padding: 0 20px;
    background: #fff;
}

.active-links {
    margin-top: 10px;
}

.active-links a {
    position: relative;
    display: block;
    float: left;
    width: 50%;
    font-size: 14px;
    .tar;
    &:nth-of-type(3){color:#D63E3E;.tar;}
    &:first-child{.tal;}
}

.active-links a + a:before {
    position: absolute;
    top: 5px;
    left: 0;
    height: 14px;
    border-left: 1px solid #ffffff;
    content: '';
}

.passport-page button, .passport-page .btn-red {
    width: 100%;
    height: 50px;
    font-size: 16px;
    color: #fff;
    background: #D63E3E;
}

.passport-page .btn-red {
    line-height: 50px;
}

.passport-page button:hover, .passport-page .btn-red:hover {
    background: #FF4A4A;
}
.fl{float:left;}
.fr{float:right;}
.tal{text-align:left;}
.tac{text-align:center;}
.tar{text-align:right;}
.vat{vertical-align:top;}
.vam{vertical-align:middle;}
.vab{vertical-align:bottom;}
.hidden{display:none;}
.hover-pointer{cursor:pointer;}
.inline-block{display:inline-block;}
.fz20{font-size:20px;}
.fz22{font-size:22px;}
.fz24{font-size:24px;}
.fz26{font-size:26px;}
.fz28{font-size:28px;}
.fz30{font-size:30px;}

.pl5{padding-left:5px;}
.pr5{padding-right:5px;}
.pt5{padding-top:5px;}
.pb5{padding-bottom:5px;}

.pl10{padding-left:10px;}
.pr10{padding-right:10px;}
.pt10{padding-top:10px;}
.pb10{padding-bottom:10px;}

.pl15{padding-left:15px;}
.pr15{padding-right:15px;}
.pt15{padding-top:15px;}
.pb15{padding-bottom:15px;}

.pl20{padding-left:20px;}
.pr20{padding-right:20px;}
.pt20{padding-top:20px;}
.pb20{padding-bottom:20px;}

.pl25{padding-left:25px;}
.pr25{padding-right:25px;}
.pt25{padding-top:25px;}
.pb25{padding-bottom:25px;}

.pl30{padding-left:30px;}
.pr30{padding-right:30px;}
.pt30{padding-top:30px;}
.pb30{padding-bottom:30px;}

.ml5{margin-left:5px;}
.mr5{margin-right:5px;}
.mt5{margin-top:5px;}
.mb5{margin-bottom:5px;}

.ml10{margin-left:10px;}
.mr10{margin-right:10px;}
.mt10{margin-top:10px;}
.mb10{margin-bottom:10px;}

.ml15{margin-left:15px;}
.mr15{margin-right:15px;}
.mt15{margin-top:15px;}
.mb15{margin-bottom:15px;}

.ml20{margin-left:20px;}
.mr20{margin-right:20px;}
.mt20{margin-top:20px;}
.mb20{margin-bottom:20px;}

.ml25{margin-left:25px;}
.mr25{margin-right:25px;}
.mt25{margin-top:25px;}
.mb25{margin-bottom:25px;}

.ml30{margin-left:30px;}
.mr30{margin-right:30px;}
.mt30{margin-top:30px;}
.mb30{margin-bottom:30px;}

.br3{border-radius:3px;}
.br4{border-radius:4px;}
.br5{border-radius:5px;}
.br6{border-radius:6px;}
.mb50{ margin-bottom: 50px;}
.pt40{ padding-top:40px;}
.fw500{ font-weight:500;}